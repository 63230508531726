import React from 'react';

const SvgArea = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 23" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-5-1h26.1v26.1H-5z" />
      <path
        d="M8.05 21.56c4.592-6.701 6.887-11.346 6.887-13.86a6.888 6.888 0 0 0-13.774 0c0 2.514 2.295 7.159 6.887 13.86z"
        stroke="#38383F"
        strokeWidth={0.725}
      />
      <path
        d="M11.464 4.452a.362.362 0 0 1-.525.5 3.974 3.974 0 0 0-2.889-1.24 3.975 3.975 0 0 0-2.901 1.252.362.362 0 1 1-.527-.497 4.7 4.7 0 0 1 3.428-1.48 4.7 4.7 0 0 1 3.414 1.465zM4.623 10.912a.362.362 0 0 1 .525-.5 3.974 3.974 0 0 0 2.889 1.24 3.975 3.975 0 0 0 2.901-1.252.363.363 0 0 1 .527.497 4.7 4.7 0 0 1-3.428 1.48 4.7 4.7 0 0 1-3.414-1.465z"
        fill="#38383F"
      />
    </g>
  </svg>
);

export default SvgArea;
