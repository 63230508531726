import React from 'react';

const SvgItinerary = props => (
  <svg width="1em" height="1em" viewBox="0 0 21 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-1h26.026v26.026H-2z" />
      <path
        d="M2.712 23.204a2.53 2.53 0 1 1 0-5.06 2.53 2.53 0 0 1 0 5.06zm0-.723a1.807 1.807 0 1 0 0-3.615 1.807 1.807 0 0 0 0 3.615zM16.95 1.355a.361.361 0 1 1 .511-.511l3.336 3.336a.361.361 0 1 1-.51.511L16.95 1.355z"
        fill="#38383F"
        fillRule="nonzero"
      />
      <path
        d="M20.797 1.355a.361.361 0 1 0-.51-.511L16.95 4.18a.361.361 0 1 0 .511.511l3.336-3.336z"
        fill="#38383F"
        fillRule="nonzero"
      />
      <path
        d="M7.44 20.117c6.526-.8 9.986-2.222 10.378-4.265.587-3.064-3.96-4.523-9.154-4.523S2.146 9.436 6.033 6.568c2.59-1.913 5.429-2.99 8.514-3.23"
        stroke="#38383F"
        strokeWidth={0.751}
        strokeLinecap="round"
        strokeDasharray="1.502"
      />
    </g>
  </svg>
);

export default SvgItinerary;
