import React from 'react';

const SvgDuration = props => (
  <svg width="1em" height="1em" viewBox="0 0 23 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-3-3h26.1v26.1H-3z" />
      <path
        d="M19.814 9.366a.363.363 0 0 1-.723.05C18.764 4.684 14.819.987 10.05.987a9.062 9.062 0 1 0 0 18.125.363.363 0 0 1 0 .726c-5.405 0-9.788-4.383-9.788-9.788S4.645.262 10.05.262c5.15 0 9.411 3.993 9.764 9.104zM11.914 19.66a.362.362 0 1 1-.137-.712 8.992 8.992 0 0 0 1.344-.369.362.362 0 1 1 .245.682c-.472.17-.957.304-1.452.4zm2.834-1.022a.363.363 0 0 1-.348-.636 9.075 9.075 0 0 0 1.167-.762.363.363 0 0 1 .442.575 9.8 9.8 0 0 1-1.26.823zm2.439-1.89a.362.362 0 0 1-.529-.496c.319-.34.611-.703.874-1.087a.362.362 0 1 1 .598.41c-.284.414-.6.806-.943 1.173zm1.687-2.46a.362.362 0 1 1-.653-.314c.202-.418.37-.853.505-1.299a.362.362 0 1 1 .694.21 9.723 9.723 0 0 1-.546 1.404z"
        fill="#38383F"
        fillRule="nonzero"
      />
      <path
        d="M19.436 9.377l2.307-2.307a.363.363 0 0 1 .513.512l-2.82 2.82-2.82-2.82a.363.363 0 0 1 .513-.512l2.307 2.307zM10.412 9.688H14.4a.363.363 0 0 1 0 .724H9.687V4.25a.363.363 0 0 1 .726 0v5.438z"
        fill="#38383F"
      />
    </g>
  </svg>
);

export default SvgDuration;
