import React from 'react';
import { toast, ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './toastContainer.module.scss';

toast.configure();
const toastProps = {
  autoClose: 3000,
  closeOnClick: true,
  draggablePercent: 60,
  hideProgressBar: true,
  pauseOnHover: true,
  transition: Slide,
};

export default function index() {
  return (
    <ToastContainer
      {...toastProps}
      className={styles.container}
      toastClassName={styles.toastClassName}
      bodyClassName={styles.bodyClassName}
      progressClassName={styles.progressClassName}
    />
  );
}
