import React from 'react';

const SvgCost = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 19" {...props}>
    <g transform="translate(-1 -3)" fill="none" fillRule="evenodd">
      <path d="M0 0h25.74v25.74H0z" />
      <path
        d="M9.118 18.779l.437.111a9.961 9.961 0 0 0 2.471.308c.85 0 1.68-.105 2.472-.308l.436-.111.395 2.106h1.831l.66-3.523.139-.24c1.335-1.161 2.082-2.663 2.082-4.252 0-3.471-3.57-6.328-8.015-6.328-1.24 0-2.439.223-3.527.646l-.222.086-2.339-1.8.611 2.774-.206.163c-.854.679-1.504 1.5-1.897 2.401l-.284.24-1.838.46v2.716l1.838.46.284.24c.354.81.915 1.557 1.648 2.193l.138.241.66 3.523h1.831l.395-2.106zm2.908 1.262c-.765 0-1.517-.078-2.243-.231l-.36 1.919h-3.23l-.764-4.07a7.097 7.097 0 0 1-1.67-2.202l-2.279-.57v-4.034l2.28-.57C4.18 9.402 4.812 8.6 5.613 7.92l-.998-4.527 3.803 2.925a10.641 10.641 0 0 1 3.608-.618c4.875 0 8.86 3.187 8.86 7.171 0 1.798-.82 3.488-2.263 4.79l-.763 4.069h-3.23l-.36-1.919c-.727.153-1.479.231-2.244.231z"
        fill="#38383F"
      />
      <circle fill="#38383F" cx={6.964} cy={11.183} r={1} />
      <path
        d="M21.859 11.162c.416.309.704.236.873-.203.16-.415-.023-.643-.559-.643a1.5 1.5 0 0 0-.441.074c-.112.327-.073.624.127.772zm1.48.032c-.328.85-1.115 1.049-1.867.49-.317-.234-.46-.578-.456-.955a6.07 6.07 0 0 0-.455.31.325.325 0 1 1-.389-.522c.383-.284.74-.499 1.077-.642.143-.271.348-.532.605-.756.554-.482 1.324-.702 2.294-.673a.325.325 0 1 1-.02.65c-.818-.025-1.43.15-1.847.514a2.197 2.197 0 0 0-.062.056c.94.02 1.448.676 1.12 1.528zM14.55 7.625a.422.422 0 1 1-.338.773c-.555-.243-1.28-.382-2.052-.382-.782 0-1.514.143-2.071.39a.422.422 0 1 1-.343-.77c.67-.299 1.519-.463 2.414-.463.884 0 1.724.16 2.39.452z"
        fill="#38383F"
      />
    </g>
  </svg>
);

export default SvgCost;
