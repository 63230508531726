import React, { useMemo } from 'react';

export default function useExperienceDuration(stops, type) {
  const durationMessage = useMemo(() => {
    const durationInHours =
      stops.reduce((accum, stop) => {
        accum += parseInt(stop.duration);
        return accum;
      }, 0) / 60.0;

    let durationMessage = `${durationInHours} hours`;
    if (durationInHours == 1.0) {
      durationMessage = `${durationInHours} hour`;
    }
    if (durationInHours > 4.0) {
      durationMessage = `${durationInHours} hours +`;
    }

    return durationMessage;
  }, [stops]);
  return durationMessage;
}
