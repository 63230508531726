import { addDays, differenceInCalendarDays, parseISO, format } from 'date-fns';

const timeZoneConfig = { timeZone: 'America/Chicago' };

// Note: only accurate (to human relevant precision) for up to one day b/c variable length of months and years - damn earth and gregorian calendar
/**
 * Return humanized string for duration
 * @param {number} minutesParam - number of minutes to convert to human-readable string
 * @param {number} numOfTimeframesParam - number of timeframes to include in returned value
 * @example
 *  getHumanizedDurationString(60, 2) -> 1 hr
 *  getHumanizedDurationString(90, 2) -> 1 hour 30 minutes
 */

export const getHumanizedDurationString = (
  minutesParam = 0,
  numOfTimeframesParam = 2
) => {
  if (isNaN(parseInt(minutesParam, 10))) {
    throw TypeError(
      `'minutes' argument must be a integer parseable value. Value passed: ${minutesParam}`
    );
  }

  if (isNaN(parseInt(numOfTimeframesParam, 10))) {
    throw TypeError(
      `'numOfTimeframes' argument must be a integer parseable value. Value passed: ${numOfTimeframesParam}`
    );
  }

  const minutes = parseInt(minutesParam, 10);
  const numOfTimeframes = parseInt(numOfTimeframesParam, 10);

  const EPOCHS = ['hr', 'min'];

  const DURATION_IN_MINUTES = {
    hr: 60,
    min: 1,
  };

  const timeframeStrings = [];
  EPOCHS.forEach((epoch, index) => {
    const interval =
      index === 0
        ? Math.floor(minutes / DURATION_IN_MINUTES[epoch]) // don't use modulus for first epoch
        : Math.floor(
            (minutes % DURATION_IN_MINUTES[EPOCHS[index - 1]]) /
              DURATION_IN_MINUTES[epoch]
          ); // use remainder from previous epoch as numerator

    if (interval >= 1 && timeframeStrings.length < numOfTimeframes) {
      timeframeStrings.push(
        interval === 1 ? `1 ${epoch}` : `${interval} ${epoch}s`
      );
    }
  });

  return timeframeStrings.join(' ');
};
/**
 * get the different between now and the date string in a nice format
 * @param {String} dateRangeLowerLimit - date string to parse for the "now" time
 * @param {String} dateString - date string to parse into date object for comparison
 * @returns {String}
 * @example
 *  formatDateStringRelativeToToday(2018-12-12) -> "Today, December 12"
 *  formatDateStringRelativeToToday(2018-12-13) -> "Tomorrow, December 13"
 *  formatDateStringRelativeToToday(2018-12-14) -> "Thursday, December 14"
 */
const monthDayFormat = 'MMMM d';
const dayOfWeekMonthDayFormat = 'EEEE, MMMM d';

export const formatDateStringRelativeToToday = (
  dateRangeLowerLimit,
  dateString
) => {
  const dateGroup = parseISO(dateString || new Date().toISOString());
  const now = parseISO(dateRangeLowerLimit || new Date().toISOString());
  const diffDays = differenceInCalendarDays(dateGroup, now);
  return diffDays === 0
    ? `Today, ${format(now, monthDayFormat)}`
    : diffDays === 1
    ? `Tomorrow, ${format(dateGroup, monthDayFormat)}`
    : `${format(dateGroup, dayOfWeekMonthDayFormat)}`;
};
