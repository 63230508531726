import React from 'react';
import AreaIcon from '../../svg/AreaIcon';
import CostIcon from '../../svg/CostIcon';
import DurationIcon from '../../svg/DurationIcon';
import ItineraryIcon from '../../svg/ItineraryIcon';
import styles from './experienceInfoItem.module.scss';

const TYPE_CONFIG = {
  area: {
    icon: <AreaIcon />,
    title: 'Area',
  },
  cost: {
    icon: <CostIcon />,
    title: 'Cost',
  },
  duration: {
    icon: <DurationIcon />,
    title: 'Duration',
  },
  itinerary: {
    icon: <ItineraryIcon />,
    title: 'Itinerary',
  },
};

function ExperienceInfoItem({ size, type, value }) {
  const config = TYPE_CONFIG[type];
  return (
    <div className={styles.container}>
      <i className={`${styles.icon} ${styles[size]}`}>{config.icon}</i>
      <div className={styles.text}>
        <span className={styles.title}> {config.title}</span>
        <h4 className={styles.value}>{value}</h4>
      </div>
    </div>
  );
}

export default ExperienceInfoItem;
