'use strict';
import { useState, useLayoutEffect } from 'react';
import _throttle from 'lodash.throttle';
import window from 'window-or-global';

let supportsPassive = false;
try {
  const opts = Object.defineProperty({}, 'passive', {
    get: function() {
      supportsPassive = true;
    },
  });
  window.addEventListener('testPassive', null, opts);
  window.removeEventListener('testPassive', null, opts);
} catch (e) {}

let getPosition = () => ({
  x: window.pageXOffset,
  y: window.pageYOffset,
});

let defaultOptions = {
  throttle: 100,
};

function useWindowScrollPosition(options) {
  let opts = Object.assign({}, defaultOptions, options);

  let [position, setPosition] = useState(getPosition());

  useLayoutEffect(() => {
    let handleScroll = _throttle(() => {
      setPosition(getPosition());
    }, opts.throttle);

    window.addEventListener(
      'scroll',
      handleScroll,
      supportsPassive ? { passive: true } : false
    );

    return () => {
      handleScroll.cancel();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return position;
}

export default useWindowScrollPosition;
